<template>
  <div class="container">
    <h4>Billing information</h4>
    <p>Show how much you used Attini for a given month and how much your organization will be billed.
    </p>
    <div class="row justify-content-md-center" style="margin-top: 40px">
      <div class="col-md-auto">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
        <span style="padding-left: 20px; padding-right: 20px">
         {{ month }}
        </span>
          </button>
          <ul class="dropdown-menu">
            <li class="dropdown-item" v-for="month in months" v-bind:key="month"
                @click="selectMonth(month)">
              {{ month }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-auto">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
        <span style="padding-left: 20px; padding-right: 20px">
          {{ year }}
        </span>
          </button>
          <ul class="dropdown-menu">
            <li class="dropdown-item" v-for="year in years" v-bind:key="year"
                @click="selectYear(year)">
              {{ year }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div>
      <button class="button" @click="getBillingData">Load billing data</button>
    </div>

    <div class="spinner-border" role="status" v-if="isLoading" style="margin-top: 10px">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div style="margin-top: 40px">
      <div v-if="billingDataLoaded && billingData.length===0">
        There is currently no billing data for this month
      </div>
      <table class="table border" v-for="billingUnit in billingData" v-bind:key="billingUnit">
        <thead>
        <td colspan="4">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">
                Account: {{ billingUnit.billingUnitKey.account }}
              </th>
              <th scope="col">
                Region:  {{ billingUnit.billingUnitKey.region }}
              </th>
              <th scope="col">
                Environment:  {{ billingUnit.billingUnitKey.environment }}
              </th>
            </tr>
            </thead>
            <tbody>
            </tbody>
          </table>

        </td>
        <tr>
          <th scope="col">
            Distribution
          </th>
          <th scope="col">
            Steps
          </th>
          <th scope="col">
            Group
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="billingItem in billingUnit.billingItems" v-bind:key="billingItem">
          <td>{{ billingItem.distribution }}</td>
          <td>{{ billingItem.totalBillableSteps }}</td>
          <td>{{ billingItem.accountGroupName }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th scope="col" colspan="2">Total cost:</th>
          <td>{{ billingUnit.cost }}$</td>
        </tr>
        </tfoot>
      </table>

      <div v-if="billingData.length!==0" style="padding-bottom: 50px">
        <h5>
          Summery
        </h5>
        <ul class="list-group mx-auto justify-content-center">
          <li class="list-group-item">
            Cost: {{totalCost}} $
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>

import {AttiniApi} from "@/AttiniApi";

export default {
  name: "BillingInformation",

  created() {
    this.setYears();
    this.month = this.months[new Date().getMonth()]
    this.year = new Date().getFullYear();
  },
  data() {
    return {
      billingData: [],
      month: '',
      year: '',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      years: [],
      billingDataLoaded: false,
      isLoading: false,
      totalCost: 0
    }
  },
  methods: {
    async selectMonth(month) {
      this.month = month;
    },
    async selectYear(year) {
      this.year = year;
    },
    async calcTotalCost() {
      this.totalCost = this.billingData.map(value => value.cost).reduce((previousValue, currentValue) => previousValue + currentValue);
    },
    async setYears() {
      let thisYear = new Date().getFullYear();
      this.years = Array.from([0, -1, -2, -3, -4], (x) => x + thisYear)
    },
    async getBillingData() {
      this.isLoading = true;
      AttiniApi.get("billing-data/" + this.year + "/" + this.month)
          .then((result) => {
            this.billingData = result.data;
            this.billingDataLoaded = true;
            this.isLoading = false;
            this.calcTotalCost();
          })
          .catch(() => {
            this.errorMessage = "Could not load billing data"
            this.isLoading = false;
          })
    }
  }
}
</script>

<style scoped>
.btn-secondary {
  background-color: #006A6D;
  color: white;
}


</style>